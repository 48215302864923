.loadingContainer {
  padding: 16px 122px 0 122px;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.flex1{
  flex: 1;
}

.flex3{
  flex: 3;
}

.margin100{
  margin-right: 100px;
}

.flex2{
  flex: 4;
}

.spaceBtw {
  justify-content: space-between;
}