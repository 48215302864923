.gathDetailContainer {
  width: 100%;
  height: 100%;
}

.gathPlaceDate {
  height: 19px;
  width: 327px;
  display: flex;
  align-items: center;
}

.titleAttendContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 32px;
  color: var(--cobuYellow);
  padding-left: 24px;
}

.gathDetailContent {
  display: flex;
  justify-content: space-between;
}

.aboutContainer {
  height: 19px;
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
  font-weight: bold;
  font-size: 16px;
  color: var(--cobuDarkBlue);
  padding-left: 24px;
}

.buildingContainer {
  height: 19px;
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
  font-weight: bold;
  font-size: 16px;
  color: var(--cobuDarkBlue);
}

.descriptionBox {
  width: 396px;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
}

.descriptionContainer {
  font-size: 16px;
  color: var(--cobuDarkBlue);
  margin-top: 16px;
}

.infoBox {
  width: 288px;
  height: 238px;
  display: flex;
  background: #ffffff;
  border: 2px solid rgba(39, 33, 99, 0.1);
  box-sizing: border-box;
  border-radius: 12px;
  border-left: none;
  margin: 20px 24px 20px 20px;
}

.gathDateLocation {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  color: var(--cobuDarkBlue);
}

.attendButton {
  width: 180px;
  height: 45px;
  border-radius: 4px;
  background-color: var(--cobuYellow);
  color: var(--cobuDarkBlue);
  border: none;
  padding: 0;
  font-size: 16px;
  color: var(--cobuDarkBlue);
  font-weight: bold;
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.bringContainer {
  height: 75px;
  background: rgba(39, 33, 99, 0.1);
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 20px;
  margin-top: 16px;
}

.bringContainer p {
  color: var(--cobuDarkBlue);
}

.yellowBox {
  width: 15px;
  height: 100%;
  padding: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #fec100;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 15px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px;
}

.infoDate {
  height: 30px;
  font-weight: 500;
  font-size: 16px;
  color: var(--cobuDarkBlue);
  border-top-right-radius: 8px;
}

.infoCreator {
  height: 30%;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed var(--cobuLightGreyPointTwo);
  display: flex;
  padding-bottom: 5px;
}

.creatorImageContainer {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.creatorProfileImage {
  height: 100%;
  width: auto;
}

.creatorContainer {
  display: flex;
}
.creatorName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  color: var(--cobuDarkBlue);
  padding-left: 10px;
}

.gathCreator {
  font-size: 12px;
  color: var(--cobuLightGreyPointFive);
}

.locationAttendingContainer {
  height: calc(70% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: var(--cobuDarkBlue);
  font-size: 16px;
}

.iconTextContainer {
  display: flex;
  align-items: center;
}

.icon {
  width: 15px;
  height: 20px;
  padding: 5px;
}

.addCalendarButton {
  width: 240px;
  height: 26px;
  display: flex;
  justify-content: center;
  border: none;
  align-items: center;
  border-radius: 4px;
  background-color: var(--cobuLightGreyPointOne);
  color: var(--cobuDarkBlue);
  font-size: 12px;
  font-weight: 500;
}
