/* Container Styles */
.uploaderContainer {
    padding: 20px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    text-align: center;
}

/* Uploaded Images Section */
.uploadedImagesContainer {
    margin-top: 20px;
}

.imageGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.imageWrapper {
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    border-radius: 8px;
    overflow: hidden;
}

.imageWrapper:hover {
    transform: scale(1.05);
}

.uploadedImage {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

/* Remove Icon */
.removeIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    background: rgba(255, 0, 0, 0.8);
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.removeIcon:hover {
    background: red;
}

/* Loading Container */
.loadingContainer {
    padding: 16px 122px 0 122px;
    justify-content: center;
    align-items: center;
}


/* Zoomed Image Overlay */
.zoomedImageOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.zoomedImageContainer {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
}

.zoomedImage {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 34px;
    height: 34px;
    background: rgba(255, 0, 0, 0.8);
    color: white;
    font-size: 24px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    border-radius: 50%;
}

.closeButton:hover {
    background: red;
}