$largeScreenPageWidth: 40vw;

.container {
  @media screen and (min-width: 750px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  background-color: black;
}

.thankyouContent {
  display: flex;
  height: 100vh;
}

.content {
  @media screen and (min-width: 750px) {
    width: $largeScreenPageWidth;
  }
}

.hedingSection {
  color: var(--cobuWhite);
  text-decoration: underline;
  padding: 30px;
  text-align: center;
  background-color: var(--cobuTurquoise);
  margin: 0;
}

.headerImage {
  max-width: 50%;
  height: 35%;
  align-self: start;

  @media screen and (min-width: 750px) {
    align-self: center;
  }
  padding: 5px;
  background-color: var(--backgroundColor);
}

.messageWrapper {
  display: flex;
  gap: 0 20px;
  color: var(--cobuWhite);
  white-space: pre-line;
}

.profileImage {
  min-width: 80px;
  width: 80px;
  height: 80px;
  min-height: 80px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 33px;
  font-weight: bold;
}

.noWordSpacing {
  word-spacing: 0px;
}

.cobuAppLogo {
  width: 80%;
}

.inputItem {
  display: flex;
  flex-direction: column;
}

.modal {
  position: fixed;
  z-index: 1;
  top: 0;
  overflow: auto;
  height: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 750px) {
    width: $largeScreenPageWidth;
  }
}

.modalContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // @media screen and (max-height: 700px) {
  //   transform: scale(1, 0.8);
  // }
}

.modalForm {
  background-color: white;
  margin: 20px;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.modalReferralForm {
  padding: 15px 30px;
}

.modalFormHeader {
  padding: 10px 10px 0 10px;
  background-color: #edebff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.formCloseIcon {
  align-self: flex-end;
  padding: 5px 0 5px 10px;
  margin: 0 0 4px 0;
  position: absolute;
  width: 20px;
  height: 20px;

  @media screen and (min-width: 750px) {
    position: relative;
  }
}

.formFields {
  display: flex;
  flex-direction: column;
  gap: 8px 0;
}

.shareIcon {
  width: 100px;
  height: 100px;

  @media screen and (min-width: 750px) {
    width: 150px;
    height: 150px;
  }
}

.combined-bonus {
  font-style: italic;
}