.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
}

.item {
  display: flex;
  flex-direction: column;
}
