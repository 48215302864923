.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--cobuBlack50);
  color: var(--cobuDarkBlue);
  z-index: var(--zIndexModal);
  opacity: 1;
}

.modal {
  padding: 40px;
  position: fixed;
  z-index: var(--zIndexModal);
  width: 668px;
  position: relative;
  margin: 10% auto;
  border-radius: 6px;
  background-color: var(--cobuWhite);
}

.modalTitle {
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
  color: var(--cobuDarkBlue);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
