.tableTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 24px 0 12px 0;
}

.title {
  margin-top: 32px;
  margin-bottom: 16px;
  font-weight: normal;
}

.starsContainer {
  padding-top: 3px;
}

.star {
  width: 20px;
}
