.reg-phone-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reg-phone-form-button{
    width: 420px;
    height: 60px;
}

.reg-phone-form-field {
    width: 405px !important;
    margin-bottom: 24px;
}