.container {
  padding: 30px;
}

.addNewTile {
  cursor: pointer;
  text-decoration: underline;
  color: rgba(39, 33, 99, 0.5);
  text-align: center;
}
