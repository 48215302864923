.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}

.item {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--cobuDarkBlue);
}

.twoRow {
  display: flex;
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.formHeader {
  display: flex;
  justify-content: space-between;
}

.formButtons {
  display: flex;
  flex-direction: row-reverse;
}
