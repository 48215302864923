.box {
  display: flex;
  border-radius: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  position: relative;
  height: 100%;
  top: -1px;
  left: 5px;
  border: 2px solid var(--cobuLightGreyPointOne);
  border-left: none;
  border-radius: 12px;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  height: 100%;
}

.textContainer {
  display: flex;
  align-items: space-between;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.description {
  font-size: 14px;
  line-height: 21px;
  color: var(--cobuLightGreyPointFive);
  margin-top: 4px;
}

.input {
  margin-top: 4px !important;
  width: 98%;
}

.label {
  margin-bottom: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--cobuLightGreyPointFive);
}

.errorMsg {
  margin-top: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--cobuError);
}

.numberBox {
  width: 78px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cobuLightGreyPointZeroThree);
  border-radius: 58px;
  font-weight: bold;
  font-size: 22px;
  color: var(--cobuDarkBlue);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(39, 33, 99, 0.1);
  margin: 0 16px;
  height: 100px;
  font-weight: 500;
  font-size: 16px;
  color: var(--cobuDarkBlue);
}

.commentSentimentTitle {
  font-size: 24px;
  font-weight: bold;
}
