.pointer {
  cursor: pointer !important;
}

.border-red {
  border: 2px solid var(--cobuError) !important;
}

.position-relative {
  position: relative !important;
}

.text-align-left {
  text-align: left;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex;
}

.color-light-grey-5 {
  color: var(--cobuLightGreyPointFive) !important;
}

.color-danger {
  color: var(--cobuRed);
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-size-24 {
  font-size: 24px;
}

.weight-500 {
  font-weight: 500 !important;
}

.bold {
  font-weight: bold;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-16 {
  margin-right: 16px;
}

.margin-right-24 {
  margin-right: 24px;
}

.margin-right-32 {
  margin-right: 32px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-24 {
  margin-left: 24px;
}

.margin-right-16 {
  margin-right: 16px;
}

.margin-right-40 {
  margin-right: 40px;
}

.modal-title {
  margin: 0;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--cobuDarkBlue);
}

.modal-text {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--cobuDarkBlue);
}

.modal {
  position: fixed;
  z-index: var(--zIndexModal);
  margin-top: 13%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.modal-content {
  background-color: var(--cobuWhite);
  padding: 40px;
  border-radius: 6px;
  margin: 0px 15px;
}

.modal-small-text {
  font-family: Roboto;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(39, 33, 99, 0.5);
}

.content-container {
  padding: 16px 122px 0 122px;
}

h2 {
  color: var(--cobuDarkBlue);
  font-size: 32px;
}

.height-20 {
  height: 20px;
  width: fit-content;
}

.height-50 {
  height: 50px;
}

.height-35 {
  height: 35px;
}

.font-style-normal {
  font-style: normal;
}

.modal-width {
  width: '668px !important';
}

.checkboxlayout input {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
}

.checkboxlayout p {
  float: left;
  margin-left: 10px;
  margin-top: 25px;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.align-items {
  align-items: center;
}

.warning-background {
  background-color: var(--cobuWarning);
}

.cobu-dark-blue-background {
  background-color: var(--cobuDarkBlue);
}

.cobu-turquoise-background {
  background-color: var(--cobuTurquoiseLight);
}
.cobu-gray-background {
  background-color: rgba(39, 33, 99, 0.5);
}

.light-grey-text {
  color: rgba(39, 33, 99, 0.5);
}

.underline {
  text-decoration: underline;
}

.default-text {
  color: var(--cobuDarkBlue);
}

.tableContainer {
  overflow: scroll;
  border: 2px solid rgba(39, 33, 99, 0.1);
  border-radius: 8px;
}

.tableContainer::-webkit-scrollbar {
  display: none;
}

.tableContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
