.rsvp-register-container {
  background-color: var(--cobuDarkBlue);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.rsvp-register-logo {
  width: 360px;
  height: 70px;
  margin-top: 175px;
}

.rsvp-register-text {
  width: 360px;
  height: 23px;
  margin: 32px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--cobuWhite);
}
