.grid {
  display: grid;
  grid-gap: 10px;
}

.item {
  display: flex;
  flex-direction: row;
}

.auto-grow-container {
  margin: 15px 0 0 0;
}

.row-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.add-content {
  color: var(--grey-blue-50, rgba(39, 33, 99, 0.5));
  text-decoration-line: underline;
}

#trash-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
