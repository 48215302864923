.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.completed-flyer-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0 10px;
  margin-bottom: 20px;
  align-self: flex-end;
}
