.pageWrapper {
  padding: 40px;
}

.headerWrapper > div {
  width: 40%;
}

.column {
  font-size: 18px;
  color: black;
  font-weight: 400;
  width: 200px;
}
