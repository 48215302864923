.passwordContainer {
  background-color: var(--cobuDarkBlue);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.left {
  margin: auto;
}

.right {
  max-width: 420px;
  margin-left: 64px;
  color: white;
  display: flex;
  flex-direction: column;
}

.info {
  flex-basis: 100%;
}

.successContainer {
  background-color: white;
  color: var(--cobuDarkBlue);
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  font-weight: 500;
}
