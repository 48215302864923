.paragraph {
  color: var(--cobuLightGreyPointFive);
}
.content {
  white-space: initial;
}

.seeMessage {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  color: #272163;
}
