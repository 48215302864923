.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--cobuBlack50);
  color: var(--cobuDarkBlue);
  z-index: var(--zIndexModal);
  opacity: 1;
}

.modal {
  padding: 40px;
  position: fixed;
  z-index: var(--zIndexModal);
  width: 668px;
  border-radius: 6px;
  background-color: var(--cobuWhite);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.overflowAutoScroll {
  overflow: scroll;
}

.modalStretch {
  position: fixed;
  z-index: var(--zIndexModal);
  width: 668px;
  position: relative;
  margin: 10% auto;
  border-radius: 6px;
  background-color: var(--cobuWhite);
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.buttonsStretch {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-bottom: 10px;
  margin-right: 25px;
}

.modalTitle {
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
  color: var(--cobuDarkBlue);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.modalContainer {
  max-height: 75vh;
}
