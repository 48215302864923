.content {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
}

.containerControls {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
}