.container {
  background-color: var(--cobuDarkBlue);
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.header {
  padding: 1.5% 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
  display: flex;
  justify-content: center;
}
.spinner {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}

.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--cobuBlack50);
  color: var(--cobuDarkBlue);
  z-index: var(--zIndexModal);
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centerModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cobuDarkBlue);
  height: 100vh;
}
