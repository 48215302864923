.errorContainer {
  background-color: white;
  color: var(--cobuDarkBlue);
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  font-weight: 500;
}

.errorMessage {
  margin-left: 16px;
}
