.groupList {
  padding-left: 2em;
}
.groupList li {
  list-style: none;
  font-size: 20px;
  font-weight: bold;
  color: var(--cobuDarkBlue);
}
.groupListli:before {
  display: inline-block;
  content: '\2022';
  color: var(--cobuRed);
  font-size: 30px;
  vertical-align: middle;
  line-height: 20px;
  width: 1em;
  margin-left: -1em;
}
.contentGroups {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.groupContainer {
  flex: 0 0 28%;
  padding: 1em 0.75em;
}
