.spinner {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.floatSpinner {
  overflow:auto;
  position:absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.7);
  z-index: 99;
}