
.calendar-button {
    width: 230px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--cobuWhite);
    margin: 16px;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 20px;
    font-weight: 500;
    color: var(--cobuDarkBlue);
}

.calendar-icon {
    margin-right: 12px;
}
