.container {
  display: flex;
  margin-top: 1em;
  width: 60%;
}

.star {
  cursor: pointer;
}

.containerError {
  display: flex;
  margin-top: 1em;
  border: 2px solid #ef4a4a !important;
  width: 60%;
}
