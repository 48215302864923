#header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  color: var(--cobuWhite);
  background-color: var(--cobuDarkBlue);
  height: var(--headerHeight);
  padding: 0 122px;
}

.logo {
  width: 100px;
}

.logout-button {
  margin-right: 122px;
  width: 100px;
  height: 30px;
  border-radius: 7px;
  border: none;
  background-color: var(--cobuYellow);
  color: var(--cobuWhite);
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}

.header-nav-menu {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  gap: 40px;
}

.menu-item {
  color: #FFF
}