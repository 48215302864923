.marginTop1 {
  margin-top: 25px;
}

#GroupsContainer {
  width: 90%;
  margin-left: 5%;
  margin-top: 1%;
}

#GroupsContainer h2 {
  text-align: center;
}

.group-form > div {
  margin: 10px 0;
  min-width: 200px;
} 

.group-modal-content h3,
.group-form > h3 {
  text-align: center;
}

.group-modal-content button {
  margin-top: 20px;
}

.warning-text {
  color: red;
}