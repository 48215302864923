button,
a {
  cursor: pointer;
}

button {
  color: var(--cobuDarkBlue);
  text-align: center;
  border-radius: 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

button:disabled,
button[disabled] {
  filter: opacity(0.5);
}

input,
select,
textarea {
  height: auto;
  border: 0;
  border-radius: 6px;
  border: 1px solid var(--cobuLightGreyPointTwo);
  background-color: var(--cobuWhite);
  color: var(--cobuDarkBlue);
  padding: 0;
  padding-left: 15px;
  font-size: 18px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 22px;
}

input,
textarea,
select {
  min-height: 32px;
  font-size: 16px;
}

input:not(.event-datetime-selector)[type='datetime-local']::-webkit-calendar-picker-indicator {
  background: none;
}

input[type='datetime-local'] {
  background: url(images/arrow-down.svg) no-repeat right;
  background-position: right 16px top 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input.event-datetime-selector[type='datetime-local']::-webkit-calendar-picker-indicator {
  /* background: none; */
  width: 56px;
  height: 14px;
  padding: 9px 0px 9px 8px;
  border-left: solid 1px var(--cobuLightGreyPointTwo);
  cursor: pointer;
}

input.event-datetime-selector {
  width: fit-content;
}

input.event-datetime-selector[type='date']::-webkit-calendar-picker-indicator {
  /* background: none; */
  width: 56px;
  height: 14px;
  padding: 9px 0px 9px 8px;
  border-left: solid 1px var(--cobuLightGreyPointTwo);
  cursor: pointer;
}

input.event-datetime-selector[type='date'] {
  background: url(images/arrow-down.svg) no-repeat right;
  background-position: right 16px top 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='radio'] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  padding: 4px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #272163;
  min-height: 0;
  outline: none;
  margin-right: 8px;
}

/* appearance for checked radiobutton */
input[type='radio']:checked {
  background-color: #272163;
}

input[type='checkbox'].cobu-checkbox {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  padding: 4px;
  /* background-color only for content */
  background-clip: content-box;
  border: 1px solid #272163;
  min-height: 0;
  outline: none;
  margin-right: 8px;
}

/* appearance for checked radiobutton */
input[type='checkbox'].cobu-checkbox:checked {
  background-color: #272163;
}

select {
  /* width: 100%; */
  border: 1px solid var(--cobuLightGreyPointTwo);
  padding-right: 35px;
  background: url(images/arrow-down.svg) no-repeat right;
  background-position: right 16px top 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
}

textarea {
  resize: none;
  height: auto;
  padding: 0.5rem;
  min-height: 100px;
}

input[type='text']:focus,
input[type='password']:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
.dropzone:focus {
  border: 1px solid #272163;
  outline: none !important;
}

label {
  font-size: 14px;
  line-height: 16px;
  color: rgba(39, 33, 99, 0.5);
}

h1,
h2,
h3,
h4,
h5,
p {
  color: var(--cobuDarkBlue);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.h1-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
  margin: 24px 0;
}

p {
  font-weight: normal;
}

a[href^='mailto'] {
  color: var(--cobuTurquoise);
}

#footer {
  height: var(--footerHeight);
  background-color: var(--cobuDarkBlue);
  margin: 0px;
  text-align: center;
  padding: 24px;
  height: auto;
}

#footer > .version {
  color: var(--cobuWhite);
}

#footer > .footer-content {
  color: var(--cobuWhite);
  font-size: 14px;
  font-weight: 500;

  margin: 0px;
}

.btn-primary {
  background-color: var(--cobuYellow);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.btn-secondary {
  background-color: var(--cobuLightGreyPointOne);
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.btn-danger {
  background-color: var(--cobuRed);
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.btn-large {
  height: 60px;
  border: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.btn-small {
  height: 30px;
  border: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.btn-standard {
  min-height: 35px;
  height: auto;
  min-width: 72px;
  width: auto;
  padding: 12px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.btn-secondary2 {
  background-color: #3424c2;
  color: var(--cobuWhite);
}

.btn-ghost {
  border: none;
  background: transparent;
}

.btn-ghost:hover {
  text-decoration: underline;
}

.dropzone {
  background: var(--cobuLightGreyPointZeroThree);
  border-radius: 8px;
  border: 1px dashed var(--cobuLightGreyPointTwo);
}

.content-container {
  padding: 16px 122px 0 122px;
}

.form-error {
  color: var(--cobuRed);
  font-weight: 500;
}

.toast {
  background-color: var(--cobuDarkBlue);
  padding: 16px 32px;
  border-radius: 8px;
  color: var(--cobuWhite);
}

.center-align {
  align-items: center;
}

.Toastify__toast-body {
  background-color: var(--cobuDarkBlue);
  padding: 16px 32px;
  border-radius: 8px;
  color: var(--cobuWhite);
  margin: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Toastify__progress-bar--animated {
  opacity: 0 !important;
}

.Toastify__toast-container {
  background-color: transparent;
  bottom: 100px;
  right: 122px;
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast--default {
  background-color: transparent;
  padding: 0;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 40px;
  border-radius: 8px;
  border: 1px solid var(--cobuLightGreyPointOne);
}

.search-expanded {
  position: relative;
  border: 0;
}

.search-expanded input {
  height: 43px;
  max-width: 320px;
  padding-left: 40px;
  border: 1px solid var(--cobuLightGreyPointOne);
  width: 320px;
}

.search-small {
  position: relative;
  border: 0;
}

.search-small input {
  height: 43px;
  max-width: 160px;
  padding-left: 40px;
  border: 1px solid var(--cobuLightGreyPointOne);
}

.search-input {
  margin: 0 8px 0 0;
  width: 100%;
  min-height: unset;
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}


.search-input:focus {
  border: 0 !important;
}

.search-input::placeholder {
  color: var(--cobuLightGreyPointFive);
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 13px;
}

.text-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.table-button {
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  padding: 4px 12px 4px 12px;
  margin: 0 auto;
  background-color: var(--cobuLightGreyPointOne);
  width: fit-content;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
}

.center-aligned {
  display: flex;
  justify-content: center;
  align-content: center;
}

.noData {
  border: 2px solid var(--cobuLightGreyPointOne);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
}

.menu-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.tab-text {
  color: var(--cobuDarkBlue);
  font-weight: bold;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.float-spinner-parent {
  position: relative;
}

.noraml {
  font-weight: 400;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 800;
}

.label {
  font-size: 14;
}

.paragraph {
  font-size: 16px;
}

.sub-heading {
  font-size: 18px;
}

.heading {
  font-size: 26px;
}

.heading2 {
  font-size: 24px;
}

.heading3 {
  font-size: 22px;
}

.heading4 {
  font-size: 20px;
}

.title {
  font-size: 32px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}
