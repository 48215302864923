.property-container {
  margin: 50px;
}

.tag-input {
  width: 100%;
}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.single-assignment {
  flex: 1.5;
}

.bulk-assignment {
  flex: 1;
}

.tag-attributes {
  flex: 1.5;
}


input[type="file"] {
  display: none;
}

.csv-container {
  display: flex;
  flex-direction: row;
  gap: 0 10px;
}