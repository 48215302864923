.filterField {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: fit-content;
  border-radius: 8px;
  border: 1px solid var(--cobuLightGreyPointOne);
  padding: 0 16px;
  margin: 0 8px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
}

.filterSearchContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.search {
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--cobuLightGreyPointOne);
}
.searchIconContainer {
  width: 14px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBy {
  display: flex;
  width: 310px;
  border: none;
}

.selectContainer {
  width: 160px;
  border: 1px solid var(--cobuLightGreyPointOne);
}
.addUserButton {
  height: 35px;
  display: flex;
  align-items: center;
}
.container {
  display: flex;
  justify-content: space-between;
}

.tableContainer {
  width: 'fit-content';
}
