.rsvp-left-container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 420px;
  height: 263px;
  margin-right: 58px;
}

.rsvp-button-container {
  width: 420px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  line-height: 24px;
}

.rsvp-login-button {
  width: 200px;
}

.rsvp-register-button {
  width: 200px;
  height: 60px;
  border-radius: 12px;
  background-color: inherit;
  color: var(--cobuYellow);
  border: 2px solid var(--cobuYellow);
  font-size: 20px;
  font-weight: 500;
  font-family: Roboto;
  cursor: pointer;
}
.rsvp-image-container {
  width: 602px;
  height: 410px;
  background-color: inherit;
}

.rsvp-image-container > img {
  width: 100%;
}

.terms {
  align-self: flex-start;
  color: var(--cobuWhite);
}

.terms > a {
  color: var(--cobuYellow);
}

.mobileWrapper {
  background-color: var(--cobuDarkBlue);
  height: 100vh;
  width: 100vw;
}

.mobileWrapper .skipBtn {
  margin-top: 10px;
  margin-left: 12px;
  font-size: 17px;
  line-height: 20px;
}

.mobileContent {
  display: flex;
  flex-direction: column;
  color: white;
}

.mobileContent .contentMain {
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 12px;
  text-align: center;
}

.RsvpDescription {
  color: var(--cobuWhite);
  font-size: 18px;
  line-height: 27px;
}

.mobileContent .contentMain h1 {
  color: var(--cobuYellow);
}

.mobileContent .contentMain p {
  color: var(--cobuWhite);
  font-size: 16px;
  line-height: 24px;
}
