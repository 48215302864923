.boolean-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
  }
  
  .boolean-box--true {
    background-color: green;
  }
  
  .boolean-box--false {
    background-color: red;
  }