.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50vw;
}

.tableTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin: 24px 0 12px 0;
}

.title {
  margin: 24px 0;
  font-weight: normal;
}

.merchantCell:hover {
  text-decoration: underline;
}
