$rhombusOffset: 40px;
$rhombusBorderOffset: $rhombusOffset + 1px;
$rhombusMargin: $rhombusOffset - 1px;
$largeScreenPageWidth: 40vw;

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--rhombusColor, transparent);

  padding: 25px;
  @media screen and (min-width: 750px) {
    padding: 20px 20%;
  }
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.content {
  width: 100%;
}

.bg-image {
  height: 30vh;
  background-size: 100% 100%;

  @media screen and (min-width: 750px) {
    height: 15vw;
    background-size: 100% 150%;
    background-position-y: 50%;
  }

  // &:hover {
  //   background-size: 100% 100%;
  // }
}

.rhombus {
  @extend .container;

  margin-top: $rhombusMargin;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    border-style: solid;
  }

  &::before {
    top: -$rhombusOffset;
    left: 0;
    border-top: 0px solid transparent;
    border-left: 100vw solid transparent;
    border-right: 0 solid transparent;
    border-bottom: $rhombusBorderOffset solid var(--rhombusColor, transparent);
    display: var(--showTopBorder, 'block');

    @media screen and (min-width: 750px) {
      border-left: $largeScreenPageWidth solid transparent;
    }
  }

  &::after {
    bottom: -$rhombusOffset;
    left: 0;
    border-width: $rhombusBorderOffset 100vw 0 0;
    border-color: var(--rhombusColor, transparent) transparent transparent
      transparent;
    display: var(--showBottomBorder, 'block');

    @media screen and (min-width: 750px) {
      border-width: $rhombusBorderOffset $largeScreenPageWidth 0 0;
    }
  }
}
