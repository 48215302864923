.sectionFilterContainer {
  width: 342px;
  display: flex;
  flex-direction: column;
}

.singleSectionFilter {
  height: 35px;
  background: rgba(39, 33, 99, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.singleSectionFilterOption {
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  height: 32px;
  color: var(--cobuDarkBlue);
}

.singleSectionFilterOptionSelected {
  color: var(--cobuWhite);
  background-color: var(--cobuDarkBlue);
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin: 0px 8px;
}

.filterOptionsContainer {
  max-height: 144px;
  overflow: auto;
}

.commentsCount {
  width: 24px;
  height: 24px;
  background-color: var(--cobuYellow);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.commentsCountSelected {
  color: var(--cobuDarkBlue);
}
.imgTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
