.more-actions-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  padding: 4px 12px 4px 12px;
  background-color: rgba(39, 33, 99, 0.1);
  width: fit-content;
  font-size: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.more-actions-btn-unselected {
  background-color: rgba(39, 33, 99, 0.1);
}

.more-actions-btn-selected {
  color: white;
  background-color: var(--cobuDarkBlue);
}

.more-actions-dropdown {
  margin-top: 4px;
  padding: 8px;
  position: absolute;
  width: auto;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(39, 33, 99, 0.1);
}

.more-actions-option {
  padding: 6px 0 6px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.more-actions-option:hover {
  background-color: #efeff3;
}
