.table-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.table-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
}

.selected-tab-cell {
  background-color: var(--cobuGreen);
}

.change-assignment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
