.tooltip {
  padding: 16px !important;
  background-color: var(--cobuDarkBlue) !important;
  opacity: 1 !important;
  border-radius: 4px !important;
  color: var(--tooltipText) !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 21px !important;
  max-width: 50vw;
  white-space: break-spaces;
  word-wrap: break-word;
}
