.reg-suc-container {
    width: 100%;
    height: 100vh;
    background-color: var(--cobuDarkBlue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reg-suc-title {
    font-size: 32px;
    color: var(--cobuWhite);
    font-weight: 900;
    margin-top: 210px;
}

.reg-suc-text {
    width: 610px;
    margin-top: 30px;
    font-size: 24px;
    color: var(--cobuWhite);
    text-align: center;
}

.reg-suc-building {
    font-size: 28px;
    color: var(--cobuTurquoise);
    font-weight: 500;
    margin-top: 10px;
}

.reg-sec-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 420px;
}

.reg-sec-skip {
    font-size: 18px;
    font-weight: 500;
    color: var(--cobuYellow);
    margin-top: 30px;
}