.reg-ver-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cobuDarkBlue);
}

.reg-ver-content {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reg-ver-title {
    height: 40px;
    font-weight: 900;
    font-size: 32px;
    line-height: 37px;
    color: var(--cobuWhite);
    margin-bottom: 15px;
}

.reg-ver-text {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--cobuWhite);
    margin-bottom: 25px;
}

.phone-number {
    color: var(--cobuTurquoise);
    font-weight: 500;
    margin-left: 10px;;
}

.reg-ver-footer {
    display: flex;
    flex-direction: column;
    align-items: center2;
    margin-top: 32px;
}

.reg-ver-second-text {
    font-size: 16px;
    color: var(--cobuWhite);
    margin-right: 15px;
}

.reg-ver-footer-row {
    display: flex;
    margin-bottom: 15px;
}

.reg-var-action-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--cobuYellow);
    cursor: pointer;
}