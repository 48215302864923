.loadingContainer{
    padding: 16px 122px 0 122px;
    justify-content: center;
    align-items: center;
}

.qrCode {
    width: 120px;
    height: 120px;
}
  