.rsvp-register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rsvp-register-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.separator-field-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding-top: 4px;
  border-top: 1px dashed var(--cobuLightLightGrey);
  width: 857px;
}

.rsvp-register-button-container {
  display: flex;
  justify-content: center;
}

.register-button {
  width: 420px;
  margin-top: 32px;
}

.space {
  margin-right: 16px;
}

.field-long {
  width: 405px;
}

.field-short {
  width: 260px;
}