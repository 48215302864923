.header {
  padding: 2.5% 9%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  background-color: var(--cobuDarkBlue);
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.item {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  justify-content: center;
}

.form {
  background-color: white;
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 100px;
}

.containersuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 40%;
  background-color: #f4f4f4;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.heading {
  color: #272163;
  font-size: 24px;
  margin-bottom: 20px;
}

.paragraph {
  font-size: 16px;
  color: #555;
  margin-bottom: 30px;
}

.button {
  background-color: #272163;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: #1f1a5c;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 20px 0;
}
