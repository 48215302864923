.selectUserBtn {
    width: 100%;
    border-style: solid;
    border-width: 1px;
    background-color: white;
    height: 45px;
}

.selecteduser {
    border-style: dotted;
    border-radius: 10px;
    padding: 10px;
    border-color: var(--cobuTurquoise);
    background-color: var(--cobuTurquoiseLight);
}

.userPlaceholderContainer {
    padding: 20px;
    background-color: rgba(39, 33, 99, 0.1);
    border-radius: 10px;
}

.notesContainer {
    display: flex;
    flex-direction: column;
}