.addNewQuestion {
    cursor: pointer;
    text-decoration: underline;
    color: rgba(39, 33, 99, 0.5);
    text-align: center;
}

.container {
    padding: 30px;
  }
  
.questionNameContainer {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
}

.submitSurvey {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 20px;
}