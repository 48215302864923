.modal {
  position: fixed;
  z-index: 1;
  top: 0;
  overflow: auto;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
