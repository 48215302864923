#proposed-groups > div > table > thead > tr:first-child > th {
  border-right: 2px solid rgba(39, 33, 99, 0.1);
}

#proposed-groups > div > table > thead > tr > th {
  border-right: 2px solid rgba(39, 33, 99, 0.1);
}

#proposed-groups > div > table > thead > tr > th {
  width: 120px;
}

#proposed-groups > div > table > thead > tr:first-child > th:last-child {
  border-right: none;
}

#proposed-groups > div > table > thead > tr > th:last-child {
  border-right: none;
}

#proposed-groups > div > table > tbody > tr > td:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

#proposed-groups > div > table > tbody > tr > td > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  width: 180px;
  font-weight: normal;
}

.tableColumn {
  font-size: 14px;
}

.suggestingUser {
  width: 140px !important;
}

.proposedGroupTitle {
  width: 180px;
  white-space: normal !important;
  font-weight: 500 !important;
  color: var(--cobuPurple);
}

#proposed-groups > div > table > tbody > tr > td > div::first-letter {
  text-transform: uppercase;
}

.proposedGroupDescription {
  width: 300px;
  white-space: normal !important;
}

.actionsColumn {
  width: 150px !important;
}

.rejectionReason {
  color: lightcoral;
}
