.tableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  margin: 10px 0;
}

.tableTitle {
  color: var(--cobuDarkBlue);
}

.switchSelected {
  background-color: var(--cobuDarkBlue);
  color: var(--cobuWhite);
}

.defaultSwitch {
  color: rgba(39, 33, 99, 0.5);
}
