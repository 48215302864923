.container {
  display: flex;
  justify-content: center;
}

.inputField {
  width: 405px;
  height: 61px;
  margin: 0;
}

.loginButton {
  width: 420px;
  margin-top: 20px;
}
