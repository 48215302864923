.dropdown-content {
  position: absolute;
  right: 0px;
  top: 60px;
  padding: 16px;
  min-width: 110px;
  background: var(--cobuWhite);
  border: 2px solid var(--cobuLightGreyPointOne);
  box-sizing: border-box;
  border-radius: 12px;
  z-index: var(--zIndexDropdown);
}

.info-text {
  color: var(--cobuYellow);
  font-weight: bold;
  font-size: 16px;
}

.user {
  color: var(--cobuLightGreyPointFive);
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  margin: 8px 0;
}

.log-out {
  color: var(--cobuDarkBlue);
  font-weight: bold;
  font-size: 16px;
}
