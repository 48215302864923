.content-container {
    padding: 20px;
}

.tiles-container {
    display: flex;
    flex-direction: row;
}

.tile-container {
    background-color: #000;
    border-radius: 16px;
    padding: 20px;
    width: 180px;
    height: 180px;
    color: #fff;
    cursor: pointer;
}

.title-container {
    font-size: 24px;
    font-weight: bold;
}

.title-line1 {
    color: #64b5f6; /* Blueish color */
}

.title-line2 {
    color: #f06292; /* Pinkish color */
}

.description {
    font-size: 14px;
    line-height: 1.5;
    color: #bdbdbd;
}