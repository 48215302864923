.calendarButtonContainer {
  width: 588px;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--cobuLightGreyPointZeroThree);
  padding: 8px 24px;
  border: 1px dashed var(--cobuLightGreyPointTwo);
  border-radius: 8px;
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 32px;
}

.closeIconContainer {
  display: flex;
  justify-content: flex-end;
  width: 785px;
}

.contentWidth {
  width: 668px;
}
