.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
}

.input {
    display: flex;
    flex-direction: column;
}

.header {
    display: grid;
    grid-template-columns: 1fr auto;
}

.formButtons {
    display: flex;
    column-gap: 12px;
}

.imagePicker {
    width: 50%
}