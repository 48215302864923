.roleContainer {
  font-size: 12px;
  border: 1px solid rgba(39, 33, 99, 0.1);
  border-radius: 4px;
  width: fit-content;
  padding: 4px 12px;
}

.deleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cobuErrorPointOne);
  color: var(--cobuError);
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 12px;
  width: fit-content;
}

.selectBuildingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addBuildingButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(39, 33, 99, 0.1);
  color: #272163;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 12px;
  width: fit-content;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.selectContainer {
  width: 450px;
}
