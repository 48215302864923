.wrapper {
  padding: 8px 16px;
  border: solid 1px transparent;
  width: fit-content;
  min-width: 80px;
  border-radius: 4px;
  cursor: pointer;
}

.wrapper {
  border: solid 1px var(--cobuLightGreyPointThree);
}

.wrapper > div {
  margin: 8px;
}
