.invalid-block-container {
  display: flex;
  align-items: center;
  width: 420px;
  height: 72px;
  background-color: var(--cobuWhite);
  border-radius: 12px;
  margin-bottom: 4px;
}

.invalid-block-text {
  width: 310px;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--cobuDarkBlue);
}

.exlamation-mark-container {
  padding: 15px;
}

.empty-invalid-block-container {
  width: 420px;
  height: 72px;
  margin-bottom: 4px;
}
