.create-referral-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.diagonal-user-type-section {
  padding: 20px;
  background-color: var(--cobuLightGreyPointZeroFive);
  margin: 0 0 20px 0;
}

.diagonal-user-type-content {
  margin: 20px 0 0 0;
}

.create-referral-item {
  display: flex;
  flex-direction: column;
}
