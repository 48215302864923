.firstSwitch {
  border-radius: 10px 0 0 10px;
}

.lastSwitch {
  border-radius: 0 10px 10px 0;
}

.switch {
  border-radius: 0;
  border-left: none !important;
  border-right: none !important;
}

.switchGeneral {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 194px;
  color: rgba(39, 33, 99, 0.5);
  border: 2px solid rgba(39, 33, 99, 0.1);
}

.switchSelected {
  background-color: var(--cobuDarkBlue);
  color: var(--cobuWhite);
}
