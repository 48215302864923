.pageWrapper {
  padding: 40px;
}

.headerWrapper > div {
  width: 40%;
}

.column {
  font-size: 18px;
  color: black;
  font-weight: 400;
}

.fileUploadWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 1px dashed #27216333;
  border-style: dashed;
  background: #2721630f;
  border-radius: 8px;
  margin-top: 4px;
}

.sheetFileinput {
  display: block;
  width: 100%;
  height: 200px;
  width: 560px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
