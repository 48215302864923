.rsvpGathHeader {
  background-color: var(--cobuDarkBlue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.rsvpGathImageContainer {
  width: 816px;
  height: 315px;
  overflow: hidden;
  margin-top: 24px;
}

.gathImage {
  display: block;
  width: 100%;
  height: auto;
}

.rsvpGathContent {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rsvpGathDetailContainer {
  width: 816px;
  border-radius: 10px;
  background: var(--cobuWhite);
  box-shadow: 0px 4px 10px rgba(39, 33, 99, 0.1);
}
