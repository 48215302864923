.contentWidth {
    width: 282px;
}

.rsvpCloseIconContainer {
    display: flex;
    justify-content: flex-end;
    width: 392px;
}

.rsvpButton {
    height: 55px !important;
    margin-top: 16px;
}

.cannotAttendButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    width: 140px;
    background: #E9E9EF;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    margin-right: 16px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
}

.saveButton {
    height: 35px;
    width: 60px;
    font-size: 16px;
}