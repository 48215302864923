.pagination {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.main-header {
  border-bottom: 2px solid rgba(39, 33, 99, 0.1);
  background-color: #f4f4f8;
  font-size: 16px;
  font-weight: bold; 
  text-align: center;
  padding: 12px 10px; 
}

.sub-header {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding: 8px 10px;
}