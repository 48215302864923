.minWidth150 {
  width: 150px;
}

.menuIcons {
  min-width: 80px;
}

.floatLeft {
  float: left;
}

.dropdown-actions-btn-unselected {
  background-color: white;
}

.dropdown-actions-btn-selected {
  background-color: white;
}

.dropdown-actions-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.5px;
  border-color: lightgray;
  height: 30px;
  padding: 4px 12px 4px 12px;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-actions-dropdown {
  margin-top: 4px;
  padding-top: 8px;
  padding-bottom: 4px;
  position: absolute;
  width: 150px;
  background: white;
  box-shadow: 0px 0px 6px 0px rgba(39, 33, 99, 0.1);
  z-index: 99;
}

.dropdown-actions-option {
  padding: 8px 0 8px 16px;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-actions-option:hover {
  background-color: #efeff3;
}
