.gatherings-container {
  padding: 24px 122px 0 122px;
}

.gatherings-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 194px;
  border: 2px solid rgba(39, 33, 99, 0.1);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(39, 33, 99, 0.5);
}

.gatherings-btn-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  width: 194px;
  border: 2px solid rgba(39, 33, 99, 0.1);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  background-color: var(--cobuDarkBlue);
}

.gatherings-btn-selected > p {
  color: var(--cobuWhite);
}

.upcoming {
  border-radius: 10px 0 0 10px;
}

.past {
  border-left: 0px;
  border-right: 0px;
}

.template {
  border-radius: 0 10px 10px 0;
}

.middle {
  border-right: none;
  border-left: none;
}
.gatherings-new-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  height: 35px;
  width: 179px;
  border-radius: 8px;
  background: var(--cobuYellow);
  color: var(--cobuDarkBlue);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.gathering-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cobuBlack50);
  cursor: pointer;
}

.gathering-delete-modal {
  padding: 40px;
  position: fixed;
  z-index: var(--zIndexModal);
  margin-top: 250px;
  width: 668px;
  border-radius: 6px;
  background-color: var(--cobuWhite);
}

.gathering-delete-modal-btns {
  display: flex;
  float: right;
}

.gathering-delete-modal-btn {
  padding: 8px 12px 8px 12px;
  width: max-content;
  border-radius: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.btn-cancel {
  background-color: #e9e9ef;
}

.btn-delete {
  background-color: var(--cobuYellow);
}

.gathering-container {
  padding: 40px;
}
