.profileImage {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.row {
    display: flex;
    flex-direction: row;
}

.spaceBtw {
    justify-content: space-between;
}

.alignCenter {
    align-self: center;
}

.phoneDetailsView {
    background-color: #E7E6ED;
    padding: 10px;
    border-radius: 5px;
    align-self: center;
}

.tableViewMin {
    flex: 30%;
}

.tableViewMax {
    flex: 70%;
}

.seperator {
    background-color: #D3D3D3;
    height: 1px;
}

.loadingContainer {
    padding: 16px 122px 0 122px;
    justify-content: center;
    align-items: center;
}

.container {
    border-radius: 10px;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color: 'red';
    border-style: solid;
    padding: 8px
}

.noData {
    border: 2px solid var(--cobuLightGreyPointOne);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;
}

.titleLabel {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
}