.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: grid;
  grid-gap: 24px;
}

.content {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px dashed rgba(39, 33, 99, 0.2);
  background: rgba(39, 33, 99, 0.03);
}

.code {
  padding: 24px;
  max-height: 200px;
}

.toggleContainer {
  display: flex;
  border-radius: 8px;
  border: 1px dashed rgba(39, 33, 99, 0.2);
  background: rgba(39, 33, 99, 0.03);;
  align-items: center;
  padding: 20px 24px;
}

.newQRCode {
  background-color: #CEEEF4; /* Light blue */
  padding: 16px;
  border-radius: 8px;
}

.originalQRCode {
  background-color:  #FCEFCA; /* Light yellow */
  padding: 16px;
  border-radius: 8px;
}

.codeWrap {
  display: flex;
  gap: 16px;
}

.qrCode {
  width: 100px;
  height: 100px;
}

.CodeDetail p {
  margin: 4px 0;
}

