.buildinglist-container {
  padding: 50px;
}

.buildinglist-title {
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--cobuDarkBlue);
  margin: 0 0 20px 0;
}

.buildinglist-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.button-container {
  display: flex;
  width: 189px;
}

.button-width {
  width: 189px;
}

.building-header-buttons {
  display: flex;
  flex-direction: row;
}
