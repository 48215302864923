.register-phone-container {
  width: 100%;
  height: 100vh;
  background-color: var(--cobuDarkBlue);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.reg-phone-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 610px;
  margin-top: 150px;
}

.reg-phone-title-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 40px;
  font-size: 32px;
  color: var(--cobuWhite);
  font-weight: 900;
  margin-top: 20px;
}

.reg-phone-text-container {
  display: flex;
  align-items: center;
  text-align: center;
  height: 62px;
  font-size: 16px;
  color: var(--cobuWhite);
  margin: 24px 0;
}
