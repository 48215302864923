.buildingCardContainer {
  display: flex;
  flex-direction: column;
}

.buildingCard {
  position: relative;
  width: 275px;
  height: 250px;
  border-radius: 12px;
  overflow: hidden;
}

.buildingCardImg {
  display: block;
  width: 275px;
  height: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buildingCard-icon {
  width: 135px;
  height: 140px;
}

.buildingCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(39, 33, 99, 0.4);
}

.buildingCard:hover .buildingCardOverlay {
  opacity: 1;
}

.buildingCardTitle {
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--cobuDarkBlue);
  max-width: 275px;
  margin: 8px 0 0 0;
}

.defaultImageBuilding {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cobuLightGreyPointOne);
}

.defaultImageBuilding > svg {
  fill: var(--cobuLightGreyPointOne);
}

.defaultImageBuilding:hover > svg {
  fill: var(--cobuDarkBlue);
  transition: 0.5s ease;
}
