.card {
  display: flex;
  border-radius: 12px;
  background-color: var(--cobuRed);
  width: 382px;
}

/* Add some padding inside the card container */
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  position: relative;
  height: 100%;
  left: 5px;
  border: 2px solid var(--cobuLightGreyPointOne);
  border-left: none;
  border-top: none;
  border-radius: 12px;
}

.containerImg {
  height: 90px;
  width: 100%;
}

.img {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.imgModal {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  height: 100%;
}

.contentTittleh2 {
  color: var(--cobuRed);
  font-size: 18px;
}

.contentBodyh3 {
  color: var(--cobuDarkBlue);
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 1em;
}

.contentLinks {
  margin-top: 1.5em;
}

.contentLinks div:nth-child(2) {
  border-top: 1px solid rgba(39, 33, 99, 0.1);
  border-bottom: 1px solid rgba(39, 33, 99, 0.1);
}

.contentLink {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;
}

.contentLink p {
  color: var(--cobuDarkBlue);
  font-weight: 500;
  font-size: 16px;
}

.contentLinksRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 18%;
}

.contentLinksRight p {
  color: var(--cobuDarkBlue);
  background-color: var(--cobuLightGreyPointOne);
  align-items: center;
  padding: 10px 1px;
  border-radius: 26px;
  width: 40px;
  text-align: center;
}

.pencil {
  cursor: pointer;
}

.inputTitle {
  color: var(--cobuRed);
  font-size: 18px;
  border: none;
  margin: 0;
  padding: 0;
}

.inputTitle:focus {
  border: none;
}

.contentModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;
  height: 100%;
}

.textareadesc {
  font-size: 16px;
  padding: 6px;
  margin: 0;
  font-weight: 400;
  border: 1px solid var(--cobuDarkBlue);
}
