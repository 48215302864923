.container {
  padding: 0 122px 0 122px;
  margin-top: 32px;
}

.btnCustom {
  width: auto;
  min-height: auto;
  font-weight: 500;
  text-decoration: none;
  color: var(--cobuDarkBlue);
  border-radius: 12px;
}

.control {
  display: flex;
  justify-content: space-between;
}

.controlAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upcomingEventsDates {
  padding: 8px;
  border-radius: 8px;
  border: solid 1px var(--cobuYellow);
  color: var(--cobuDarkBlue);
}

.starsContainer {
  padding-top: 3px;
}

.star {
  width: 20px;
}
