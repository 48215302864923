.gridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
}

.item {
  display: flex;
  flex-direction: column;
}

.title {
  color: var(--cobuDarkBlue);
}

.twoRow {
  display: flex;
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.formHeader {
  display: flex;
  justify-content: space-between;
}

.formButtons {
  display: flex;
  flex-direction: row-reverse;
}

.radioInpuContainer {
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--cobuLightGreyPointTwo);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 18px;
}

.disabled {
  opacity: 0.3;
  border-color: var(--cobuDarkBlueDisable);
}

.rsvpRequiredContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 24px;
  grid-template-areas: 'rsvpRequired attendees';
}

.rsvpRequired {
  grid-area: rsvpRequired;
}

.attendees {
  grid-area: attendees;
}

p {
  font-size: 14px;
  line-height: 16px;
  color: rgba(39, 33, 99, 0.5);
}

.span {
  font-size: 12px;
  color: rgba(39, 33, 99, 0.5);
}

.borderedSection {
  padding: 12px;
  background: rgba(39, 33, 99, 0.03);
  border-radius: 4px;
  border: solid 1px lightgray;
}

.unborderedSection {
  padding: 12px 0px;
}
