.searchPosition {
  position: absolute;
  right: 123px;
  top: 203px;
  display: flex;
  align-items: center;
}

.flexRow {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  align-items: center;
}

.noData {
  border: 2px solid var(--cobuLightGreyPointOne);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
}

.titleLabel {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.highlightSearch {
  border: 2px solid var(--cobuLightGreyPointOne);
}

.searchInput {
  margin: 0 8px 0 0;
  width: 150%;
  min-height: unset;
  border: 0;
  font-family: Roboto;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}

.searchInput:focus {
  border: 2px solid var(--cobuYellow);
}