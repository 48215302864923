@import './utils/variables.css';
@import './utils/layout-utils.css';
@import './utils/theme.css';

html * {
  font-family: Roboto;
}

#main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#page {
  min-height: calc(100vh - var(--headerHeight) - var(--footerHeight));
  width: 100%;
}

.input-field-container {
  margin-bottom: 4px;
}

.submit-button {
  height: 60px;
  border: 0;
  border-radius: 12px;
  background-color: var(--cobuYellow);
  color: var(--cobuDarkBlue);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
}

::placeholder {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: var(--cobuLightGreyPointFive);
}

.error-text {
  color: var(--cobuWarning);
}

.error-border {
  border: 3px solid var(--cobuWarning);
}

.form-field-error-box {
  color: var(--cobuWarning);
  font-weight: 500;
  font-size: 14px;
  height: 20px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.cobu-text {
  font-size: 20px;
  font-weight: 400;
  color: var(--cobuWhite);
  margin-top: 10px;
}

.overlay-with-modal {
  display: flex;
  justify-content: center;
}

.small-text {
  font-size: 14px;
  line-height: 19px;
  color: var(--cobuDarkBlue);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: var(--cobuLightGreyPointOne);
}

::-webkit-scrollbar-thumb {
  background: var(--cobuLightGreyPointFive);
}

.profile-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.cobu-royal-table-head {
  background-color: var(--cobuLightGreyPointFive) !important;
  color: white !important;
  font-weight: bold;
}

th.cobu-royal-table-head {
  border-right: solid 1px white;
}

.textAlignStart {
  text-align: start;
}

.flex {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  display: flex-row;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.bullet {
  position: absolute;
  padding: 4px 8px;
  z-index: 2;
  background: var(--cobuYellow);
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.2;
}

.color-warning {
  color: var(--cobuWarning);
}

.color-dark-blue {
  color: var(--cobuDarkBlue);
}

.full-width {
  width: 100%;
}

.text-capitalize {
  text-transform: capitalize;
}

.blink {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}
