.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  color: var(--cobuDarkBlue);
  z-index: var(--zIndexModal);
  opacity: 1;
}

.modal {
  background-color: var(--cobuWhite);
  padding: 24px 24px 0 24px;
  width: 620px;
  height: calc(100vh -  var(--headerHeight) -  var(--footerHeight) - 24px);
  margin-bottom: 85px;
  float: right;
  margin-top: var(--headerHeight);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.bottonContainer {
  width: 135px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  height: 20px; 
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px 8px 12px;
}

.editButton {
  background-color: var(--cobuYellow);
}

.cancelButton {
  background-color: var(--cobuLightGreyPointOne);
}

.textStyle {
  color: var(--cobuLightGreyPointFive);
  font-size: 18px;
}
.lineHeight {
  line-height: 27px;
}