.comments-container {
  padding: 24px 24px 0px 24px;
  border: 1px solid #2721631a;
  border-radius: 8px;
  height: 608px;
  overflow: auto;
}

.single-thread-container {
  padding-right: 24px;
  padding-bottom: 100px;
  overflow: auto;
  max-height: 400px;
}

.comments-container-txt {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--cobuTextBlack);
  margin-top: 0px;
}

.comments-container-content {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--cobuDarkBlue);
  margin-top: 0px;
  margin-bottom: 9px;
  max-width: 500px;
  white-space: normal;
  word-break: break-word;
}

.comments-container-content-hidden {
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--cobuLightGreyPointThree);
  margin-top: 0px;
  margin-bottom: 9px;
  max-width: 500px;
  white-space: normal;
  text-decoration: line-through;
  word-break: break-word;
}

.comment {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-top: 12px;
}

.comment:hover {
  background-color: var(--cobuLightGreyPointOne);
}

.comment-blink {
  animation: blink 1s ease-in-out 3;
}

@keyframes blink {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: var(--cobuYellow);
  }
  100% {
    background-color: white;
  }
}

.comment-child {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 56px;
  padding-top: 12px;
}

.comment-child:hover {
  background-color: var(--cobuLightGreyPointOne);
}

.pinnedComment {
  background-color: var(--cobuTurquoiseLight);
}

.comment-time {
  margin-top: 0px;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(126, 126, 126, 1);
}

.avatar {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.ellipsis {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 40px;
  right: 0;
}

.checkbox-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.small-list {
  position: absolute;
  top: 20px;
  right: 120px;
  z-index: var(--zIndexDropdown);
}

.removed-label {
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  color: white;
}

.bw {
  opacity: 0.2;
}

.new-comment-input {
  border: white;
  width: 100%;
}

.new-comment-input:focus {
  border: 0px solid white !important;
}

.new-comment-input-modal {
  width: 98%;
}

.email-txt-area {
  width: 97%;
}

.reply-input {
  border: white;
}

.reply-input:focus {
  border: 0px solid white !important;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  border-radius: 12px;
  border: 1px solid var(--cobuLightGreyPointTwo);
}

.txt-area-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--cobuLightGreyPointTwo);
}

.camera {
  align-self: center;
}

.camera:hover {
  cursor: pointer;
}

.comments-bottom-fields {
  display: flex;
}

.comment-horizontal {
  display: flex;
  margin-bottom: 12px;
}

.comment-reply-btn {
  margin-top: 0px;
  color: var(--cobuRed);
  cursor: pointer;
}

.reply-input {
  padding: 15px;
  width: 95%;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px; */
  height: 35px;
}

.single-filter {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--cobuLightGreyPointOne);
  border-radius: 4px;
  background-color: var(--cobuWhite);
  color: var(--cobuDarkBlue);
  cursor: pointer;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 0 12px;
}

.single-filter-selected {
  background-color: var(--cobuDarkBlue);
  color: var(--cobuWhite);
}

.line {
  position: absolute;
  top: 60px;
  left: 20px;
  width: 3px;
  height: 100%;
  background-color: #e9e9ef;
}

.left-line {
  position: absolute;
  top: 60px;
  left: -36px;
  width: 3px;
  height: 100%;
  background-color: #e9e9ef;
}

.curve {
  position: absolute;
  top: 30px;
  left: -34px;
  height: 3px;
  width: 32px;
  background-color: #e9e9ef;
}

.contentContainer {
  display: flex;
}

.timeFilterCommentsContainer {
  width: 830px;
}
