#form-container {
  overflow: 'auto';
}

.item {
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 0;
}

.form-container {
  background-color: #f2f2f2;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  background-color: var(--cobuWhite);
  padding: 15px;
  border-radius: 12px;
  border: 1px solid var(--cobuBlack);
}

.logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 20px 0;
  border-radius: 4px;
}

.building-logo {
  width: 100%;
  height: 10vh;
  align-self: center;
  object-fit: scale-down;
}

@media (min-width: 1025px) {
  .building-logo {
    height: 12vh;
  }
}

.ceterAlign {
  text-align: center;
}

.referredBy {
  color: #667085;
  margin: 30px 0;
  white-space: pre-line;
  font-size: 12px;
}

.referAFriend {
  font-size: 21px;
}

.footer {
  margin: 20px 0 0 0;
  color: #344054;
}

.submit-another {
  font-size: 17px;
  font-weight: bold;
  padding: 12px;
  text-align: center;
  background-color: #272163;
}

.form-submit {
  font-size: 17px;
  font-weight: bold;
  padding: 12px;
  display: block;
  width: 100%;
  background-color: #272163;
}

.footer-img {
  width: 170px;
  height: 50px;
}

.footer-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 90px 0 0 0;
}

.footer-description {
  color: #344054;
  margin: 0 0 20px 0;
  text-align: center;
}

.thankyou-container {
  height: 85vh;
}

.terms-and-condition {
  white-space: pre-line;
}
