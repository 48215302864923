.circleBackground,
.circleProgress {
  fill: none;
}

.circleBackground {
  stroke: #ddd;
}

.circleProgress {
  stroke-linecap: round;
  stroke-linejoin: round;
}