.formContainer {
  width: 770px;
  margin: 16px;
  padding: 2% 5%;
  background-color: #ffffff;
  border-radius: 8px;
}
.gridContainer {
  display: grid;
  grid-gap: 2em;
}
.item {
  display: flex;
  flex-direction: column;
}

.prefilled {
  background-color: var(--cobuLightGrey);
  color: var(--cobuLightGreyPointFive);
}

.vendorFormTextarea {
  height: 106px;
  outline: none;
  padding: 16px;
}
