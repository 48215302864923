.star {
    width: 20px;
}

.ratingContainer {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.starsContainer {
    padding-top: 3px;
  }