.container {
    border-radius: 10px;
    border-width: 1px;
    border-color: #D3D3D3;
    background-color: 'red';
    border-style: solid;
    padding: 8px
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rowTextStyle {
    display: flex;
    flex: 60%;
    background-color: 'red';
    color: var(--cobuDarkBlue);
}

.switchStyle {
    transform: scale(0.6)
}