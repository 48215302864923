.container {
  display: grid;
  grid-gap: 24px;
}

.content {
  display: flex;
  border-radius: 8px;
  border: 1px dashed rgba(39, 33, 99, 0.2);
  background: rgba(39, 33, 99, 0.03);
}

.code {
  padding: 24px;
  max-height: 200px;
}

.toggleContainer {
  display: flex;
  border-radius: 8px;
  border: 1px dashed rgba(39, 33, 99, 0.2);
  background: rgba(39, 33, 99, 0.03);;
  align-items: center;
  padding: 20px 24px;
}

.qrCode {
  width: 120px;
  height: 120px;
}

.codeWrap {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.CodeDetail {
  margin-left: 24px;
}
