.loadingContainer {
    padding: 16px 122px 0 122px;
    justify-content: center;
    align-items: center;
}

.status {
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
    text-align: center;
    font-weight: bold;
    width: 150px;
}

/* Different background colors for each status */
.pending {
    background-color: #ff9800;
    /* Orange for pending */
}

.processing {
    background-color: #2196f3;
    /* Blue for processing */
}

.completed {
    background-color: #4caf50;
    /* Green for completed */
}

.failed {
    background-color: #f44336;
    /* Red for failed */
}

.failureReason {
    color: #f44336; /* Red color for failure reason */
    font-size: 0.9em;
    padding: 5px;
    border-radius: 4px;
    white-space: normal;
    overflow: hidden;
    background-color: #ffe6e6; /* Light red background */
}

.noFailureReason {
    color: #777; /* Grey color for placeholder */
    text-align: center;
}
