.red {
  background-color: var(--cobuRed);
  color: var(--cobuWhite);
}

.yellow {
  background-color: var(--cobuYellow);
  color: var(--cobuDarkBlue);
}

.green {
  background-color: var(--cobuGreen);
  color: var(--cobuDarkBlue);
}
