.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

li {
  color: var(--cobuWarning);
}

.toggleContainer {
  display: flex;
  border-radius: 8px;
  align-items: center;
  padding: 20px 24px;
}

.bottomContainer {
  display: flex;
  width: 100%;
}

.buttonsContainer {
  width: 50%;
  text-align: end;
}

.togglesContainer {
  width: 50%;
}
