/* Cobu pink is #ED145B */
:root {
  --pink: #ED145B;
}

.tags button {
  padding: 10px 16px;
  margin: 10px;
  font-family: sans-serif;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
}

.tags button:focus {
  outline: none;
}

.tags .inactive {
  color: var(--pink);
  background-color: white;
  border: 2px solid var(--pink);
}

.tags .active {
  color: white;
  background-color: var(--pink);
  border: 2px solid var(--pink);
}

.tags .presentational-container {
  margin-bottom: 15px;
  margin-top: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tags .presentational-container p {
  margin: 0px;
  margin-right: 8px;
  padding: 0px;
}

@media (max-width: 700px) {
  .tags .presentational-tag {
    font-size: 11px;
    padding: 8px 12px;
    margin: 5px 5px;
  }
}
