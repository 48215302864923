.referral-settting-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.entrata-item-0 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.table-separater {
  background-color: black;
  width: 0.5px;
}

select {
  text-transform: capitalize;
}
