.createMerchant {
  display: flex;
  flex-direction: row;
}

.createMerchantForm {
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-right: 12px;
}

.addCategory {
  align-self: center;
  width: 320px;
  margin-top: 12px;
  margin-left: 12px;
}
