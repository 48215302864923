.tableColumn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
}

.userName {
  width: 160px;
  white-space: normal;
}

.groupColumn {
  width: 240px;
}

.building {
  width: 160px;
  white-space: normal;
}

.contentColumn {
  width: 300px;
  white-space: wrap;
  cursor: pointer;
  text-align: start;
}

.contentCreatedAt {
  color: var(--cobuTextBlack);
}

.contentCreatedAt::first-letter,
.contentColumn::first-letter {
  text-transform: uppercase;
}

.smallColumn {
  width: 80px;
}

.actionsColumn {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.actionsColumn > button {
  width: fit-content;
  height: fit-content;
  background: #2721631a;
  border-radius: 3px;
  margin-right: 8px;
  border: none;
  padding: 4px 8px;
  font-weight: 500;
}

.reviewButton {
  font-weight: 500;
  color: var(--cobuTextBlack);
}

.reviewAboveButton {
  visibility: hidden;
  font-weight: 500;
  background-color: #fec100 !important;
}

.contentImages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contentImages > div {
  display: block;
  height: 140px;
  width: 140px;
  border-radius: 8px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.viewAllImages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border-radius: 8px;
  text-align: center;
}

.viewAllImages > button {
  width: 100%;
  color: white;
  position: relative;
  top: 0px;
  left: -120px;
  background: rgba(0, 0, 0, 0.6);
}

.greenText {
  color: green;
}

.redText {
  color: red;
}

.positiveSentiment {
  background-color: var(--cobuTurquoise);
  color: white;
  border-radius: 4px;
  padding: 6px;
  text-align: center;
}

.negativeSentiment {
  background-color: red;
  color: white;
  border-radius: 4px;
  padding: 6px;
  text-align: center;
}

.undefinedSentiment {
  background-color: lightgray;
  color: white;
  border-radius: 4px;
  padding: 6px;
  text-align: center;
}

.commentDetailsActions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contentImages {
  display: flex;
  flex-direction: row;
}
