.reg-ver-input {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 10px;
}

.reg-ver-input:focus {
  outline: none;
  border: 1px solid var(--cobuYellow) !important;
}
.reg-ver-input-container {
  width: 450px;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.reg-ver-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.reg-ver-form-button {
  width: 420px;
  margin-top: 30px;
}
.reg-ver-form-input input[type='number'] {
  height: 80px !important;
  width: 80px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
  box-sizing: border-box;
  border-radius: 6px !important;
  margin: 10px !important;
  outline: none;
  color: var(--cobuWhite) !important;
  font-weight: 500 !important;
  font-size: 48px !important;
  text-align: center;
  font-family: Roboto;
}
