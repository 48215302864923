.crumbContainer {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
}

.link {
  color: var(--cobuLightGreyPointFive);
  text-decoration: none;
}

.selected {
  color: var(--cobuDarkBlue);
  text-decoration: none;
}

.hide {
  display: none;
}

.banner {
  background: var(--cobuLightGreyPointOne);
  border-radius: 4px;
  padding: 4px 8px;
}
.bannerText {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 4px 8px;
  color: var(--cobuDarkBlue);
}
