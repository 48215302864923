.passwordContainer {
  background-color: var(--cobuDarkBlue);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.left {
  margin: auto;
}

.right {
  max-width: 420px;
  margin-left: 64px;
  color: white;
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
}

.successContainer {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  font-weight: 500;
}

.text {
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  max-width: 610px;
}

.login {
  width: 420px;
}
