.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: var(--cobuDarkBlue);
  padding-top: 74px;
}

.title {
  font-weight: 900;
  font-size: 100px;
  line-height: 117px;
}

.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
