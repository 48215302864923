.login-container {
  background-color: var(--cobuDarkBlue);
  height: 94vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 326px;
  height: 146px;
  margin-right: 64px;
}

.right-container {
  width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.forgotten-password-container {
  width: 420px;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  color: var(--cobuYellow);
}

.cobu-logo {
  width: 240px;
  height: 70px;
  margin-bottom: 24px;
}

.login-text {
  width: 326px;
}
