#group-event-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  margin-top: -20px;
}

#group-event-form select {
  background: rgba(55, 72, 172, 0.3) 96% 0;
  width: 100%;
  height: 45px;
  overflow: hidden;
  border: 1px solid #3728AC;
  border-radius: 5px;
  font-size: 13px;
}

#group-event-form select:disabled {
  background: rgba(219, 219, 219, 0.5) 96% 0;
  border: 1px solid #D4D4D4;
}

.half-form-width {
  display: inline-block;
  width: 48%;
  margin: 25px 0;
}

.what-to-bring {
  margin-top: 25px;
}

.max {
  margin-top: 25px;
  width: 250px;
}

#group-event-form .img-button-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

#group-event-form .img-button-container > button {
  margin-top: 20px;
}

label.select-label {
  color: #303f9f;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 2;
}

@media only screen and (max-width: 600px) {
  .half-form-width {
      width: 100%;
  }
}