.last-sync-status-FAILED {
  text-align: center;
  padding: 4px 16px;
  border-radius: 4px;
  color: white;
  width: fit-content;
  background-color: var(--cobuRed);
}

.last-sync-status-SUCCESS {
  text-align: center;
  padding: 4px 16px;
  border-radius: 4px;
  color: white;
  width: fit-content;
  background-color: var(--cobuTurquoise);
}

#buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0px;
}

td:nth-child(1),
td:nth-child(5),
td:nth-child(6) {
  max-width: 800px !important;
}
