.dropzoneWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 193px;
  min-width: 500px;
  cursor: pointer;
}

.dropzoneWrapper p {
  color: var(--cobuDarkBlue);
}

.preview {
  max-height: 40vh;
  padding: 16px 16px 8px 16px;
  width: -webkit-fill-available;
}

.dropImageText {
  margin-top: 15px;
}
